import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "current_state_wrapper"
};
const _hoisted_2 = {
  class: "current_state"
};
const _hoisted_3 = {
  class: "current_state-item"
};
const _hoisted_4 = {
  class: "current_state-tap-value"
};
import { onMounted } from "vue";
import { useCoinForUseStore } from "@/stores/coinForUse";
export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentState',
  setup(__props) {
    const coinsForUse = useCoinForUseStore();
    onMounted(() => {
      coinsForUse.setValue(2);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "current_state-tap-text"
      }, "Монет за нажатие", -1)), _createElementVNode("div", _hoisted_4, "+" + _toDisplayString(_unref(coinsForUse).count), 1)]), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "current_state-item"
      }, [_createElementVNode("div", {
        class: "current_state-level-text"
      }, "Следующий уровень"), _createElementVNode("div", {
        class: "current_state-level-value"
      }, "10М")], -1))]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "balance"
      }, [_createElementVNode("div", {
        class: "balance_icon"
      }), _createElementVNode("div", {
        class: "balance_text"
      }, "589 788")], -1))]);
    };
  }
});