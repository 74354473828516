// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/components/MainPage.vue";
import ShopPage from "@/components/Shop/ShopPage.vue";
import BoostsPage from "@/components/Shop/BoostsPage.vue";
import SubscriptionPage from "@/components/Subscription/SubscriptionPage.vue";
import FriendsPage from "@/components/Friends/FriendsPage.vue";
import EventsPage from "@/components/Events/EventsPage.vue";

const routes = [
  { path: "/", component: MainPage },
  { path: "/shop", component: ShopPage },
  { path: "/boosts", component: BoostsPage },
  { path: "/subscription", component: SubscriptionPage },
  { path: "/friends", component: FriendsPage },
  { path: "/events", component: EventsPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
