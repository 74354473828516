import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "shop_menu"
};
const _hoisted_2 = ["onClick"];
export default /*@__PURE__*/_defineComponent({
  __name: 'ShopMenu',
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  emits: ["change-section"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const emitChangeSection = section => {
      emit("change-section", section);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.menuItems, item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.section,
          class: _normalizeClass(["shop_menu-item", {
            active: item.active
          }]),
          onClick: $event => emitChangeSection(item.section)
        }, _toDisplayString(item.title), 11, _hoisted_2);
      }), 128))]);
    };
  }
});