import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/icons/moneta.svg';
import _imports_1 from '@/assets/images/icons/ruble.svg';
const _hoisted_1 = {
  class: "shop_item-title"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "shop_item-description"
};
const _hoisted_4 = {
  class: "shop_item-description-text"
};
const _hoisted_5 = {
  class: "shop_item-price"
};
const _hoisted_6 = {
  key: 0,
  class: "shop_item-price-level"
};
const _hoisted_7 = {
  class: "shop_item-price-icon"
};
const _hoisted_8 = {
  key: 0,
  src: _imports_1,
  alt: ""
};
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  alt: ""
};
const _hoisted_10 = {
  class: "shop_item-price-value"
};
import { computed } from "vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'ShopItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: false
    },
    blockType: {
      type: String,
      required: true
    }
  },
  emits: ["item-clicked"],
  setup(__props, {
    emit: __emit
  }) {
    const emitClick = () => {
      emit("item-clicked", props.item);
    };
    const emit = __emit;
    const props = __props;
    const configHeader = computed(() => props.item.config ? props.item.config.header : null);
    const configColor = computed(() => props.item.config && props.item.config.color ? props.item.config.color : null);
    const borderStyle = computed(() => configColor.value ? {
      borderColor: configColor.value
    } : {});
    const backgroundStyle = computed(() => configColor.value ? {
      backgroundColor: configColor.value
    } : {});
    const textColor = computed(() => props.item.config && props.item.config.textColor ? {
      color: props.item.config.textColor
    } : {});
    const titleIcon = computed(() => {
      const iconMap = {
        bronze: require("@/assets/images/icons/vip_bronze.svg"),
        silver: require("@/assets/images/icons/vip_silver.svg"),
        gold: require("@/assets/images/icons/vip_gold.svg"),
        super: require("@/assets/images/icons/vip_super.svg"),
        boost: require("@/assets/images/icons/boost.svg")
      };
      return iconMap[props.item.config?.titleIcon];
    });
    const itemWidth = computed(() => {
      const rowCount = props.config && props.config.rowCount ? props.config.rowCount : 1;
      if (rowCount === 2) {
        return {
          width: "calc(50% - 10px)"
        };
      } else if (rowCount === 3) {
        return {
          width: "calc(33% - 10px)"
        };
      }
      return {
        width: "100%"
      };
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "shop_item",
        style: _normalizeStyle([borderStyle.value, itemWidth.value]),
        onClick: emitClick
      }, [configHeader.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "shop_item-header",
        style: _normalizeStyle([backgroundStyle.value, textColor.value])
      }, _toDisplayString(configHeader.value), 5)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(['shop_item-info', {
          'shop_item-info--with-header': configHeader.value
        }])
      }, [_createElementVNode("div", _hoisted_1, [titleIcon.value ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: titleIcon.value,
        alt: ""
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(__props.item.title), 1)]), __props.item.description ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "shop_item-description-icon"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.item.description), 1)])) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_5, [__props.item.level ? (_openBlock(), _createElementBlock("div", _hoisted_6, " lvl " + _toDisplayString(__props.item.level), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [__props.blockType === 'shop' ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : _createCommentVNode("", true), __props.blockType === 'boosts' ? (_openBlock(), _createElementBlock("img", _hoisted_9)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, _toDisplayString(__props.item.price) + " " + _toDisplayString(__props.blockType === "shop" ? "₽" : ""), 1)])], 4);
    };
  }
});