import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled"];
export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonMain',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    background: {
      type: String,
      default: "var(--bg-substrate)"
    },
    color: {
      type: String,
      default: "var(--white)"
    },
    fontSize: {
      type: String,
      default: "12px"
    }
  },
  emits: ["click"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const handleClick = () => {
      emit("click");
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        style: _normalizeStyle({
          background: __props.background,
          color: __props.color
        }),
        disabled: __props.disabled,
        onClick: handleClick
      }, _toDisplayString(__props.text), 13, _hoisted_1);
    };
  }
});