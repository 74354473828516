import { useCoinForUseStore } from "@/stores/coinForUse";

export class Main {
  constructor(moneta_path) {
    this.moneta_path = moneta_path;
    this.initButtonClick();
  }

  initButtonClick() {
    const button = document.querySelector(".button img");

    button.addEventListener("touchstart", () => {
      button.classList.add("active");
    });

    button.addEventListener("touchend", () => {
      button.classList.remove("active");
    });

    button.addEventListener("touchcancel", () => {
      button.classList.remove("active");
    });

    const buttonContainer = button.parentElement;
    buttonContainer.style.position = "relative";
    button.addEventListener("click", this.handleButtonClick.bind(this));
  }

  handleButtonClick(event) {
    const coinForUse = useCoinForUseStore();

    const button = event.currentTarget;
    const buttonRect = button.getBoundingClientRect();

    const clickX = event.clientX;
    const clickY = event.clientY;

    const relativeX = clickX - buttonRect.left;
    const relativeY = clickY - buttonRect.top;
    this.createCoins(relativeX, relativeY);
    this.showCoinText(relativeX, relativeY, coinForUse.count);
  }

  createCoins(x, y) {
    const numCoins = Math.floor(Math.random() * 3) + 1; // От 1 до 3 монет
    for (let i = 0; i < numCoins; i++) {
      const coin = document.createElement("img");
      coin.src = this.moneta_path;
      coin.classList.add("coin");
      coin.style.left = `${x - 10}px`;
      coin.style.top = `${y - 10}px`;
      coin.style.width = "20px";
      coin.style.height = "20px";
      coin.style.position = "absolute";

      document.querySelector(".button").appendChild(coin);

      coin.getBoundingClientRect();

      const dx = (Math.random() - 0.5) * 100; // От -50 до 50
      const dy = -Math.random() * 100 - 50; // От -50 до -150

      coin.style.transition = "transform 1s ease-out, opacity 1s ease-out";
      coin.style.transform = `translate(${dx}px, ${dy}px)`;
      coin.style.opacity = "0";

      setTimeout(() => {
        coin.remove();
      }, 1000);
    }
  }

  showCoinText(x, y, coinForUser) {
    const text = document.createElement("div");
    text.classList.add("coin-text");
    text.innerText = `+${coinForUser}`;
    text.style.left = `${x}px`;
    text.style.top = `${y}px`;

    document.querySelector(".button").appendChild(text);
    text.getBoundingClientRect();
    text.style.transform = `translateY(-50px)`;
    text.style.opacity = "0";
    setTimeout(() => {
      text.remove();
    }, 1000);
  }
}
