import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "roulette_chance_item_wrapper"
};
const _hoisted_2 = ["src"];
export default /*@__PURE__*/_defineComponent({
  __name: 'CaseChanceInfo',
  props: {
    caseInfo: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    function hexToRgb(hex) {
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = bigint >> 16 & 255;
      const g = bigint >> 8 & 255;
      const b = bigint & 255;
      return `${r}, ${g}, ${b}`;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["roulette_chance_item", __props.caseInfo.type]),
        style: _normalizeStyle({
          borderColor: __props.caseInfo.color,
          background: `rgba(${hexToRgb(__props.caseInfo.color)}, 0.05)`,
          boxShadow: `0 0 20px 3px ${__props.caseInfo.color}`
        })
      }, [_createElementVNode("div", {
        class: "roulette_chance_item_title",
        style: _normalizeStyle({
          color: __props.caseInfo.color
        })
      }, _toDisplayString(__props.caseInfo.title) + " (" + _toDisplayString(__props.caseInfo.chance) + "%) ", 5), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.caseInfo.items, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "roulette_chance_item_value",
          key: item.text
        }, [_createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_2), _createTextVNode(" " + _toDisplayString(item.text) + " (" + _toDisplayString(item.chance) + "%) ", 1)]);
      }), 128))])], 6);
    };
  }
});