import { defineStore } from "pinia";
import axios from "axios";

export const useCoinForUseStore = defineStore("coinForUse", {
  state: () => ({
    count: 0 as number,
    isLoading: false,
    error: null as string | null,
  }),
  actions: {
    setValue(value: number) {
      this.count = value;
    },
    async fetchCount() {
      this.isLoading = true;
      this.error = null;
      try {
        // const response = await axios.get("/api/coins/count");
        // this.count = response.data.count;
        this.count = 1;
      } catch (err) {
        this.error = "Не удалось загрузить количество монет.";
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
