export class Menu {
  constructor() {
    this.highlightActiveMenuItem();
    this.loadAndColorIcons();
  }

  highlightActiveMenuItem() {
    const menuItems = document.querySelectorAll(".menu_item");
    const currentPath = window.location.pathname;

    menuItems.forEach((item) => {
      const itemPath = item.getAttribute("href");

      if (itemPath === currentPath) {
        item.classList.add("active");
      }
    });
  }

  loadAndColorIcons() {
    const menuItems = document.querySelectorAll(".menu_item");

    menuItems.forEach((item) => {
      const img = item.querySelector("img");
      if (img) {
        const src = img.getAttribute("src");
        const isActive = item
          .closest(".menu_item")
          .classList.contains("active");
        const color = isActive ? "#6F72E2" : "#ffffff";

        fetch(src)
          .then((response) => response.text())
          .then((svgText) => {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
            const svgElement = svgDoc.documentElement;

            this.setSVGFillColor(svgElement, color.trim());
            svgElement.classList.add("icon");
            img.parentNode?.replaceChild(svgElement, img);
          })
          .catch((error) => {
            console.error("Ошибка при загрузке SVG:", error);
          });
      }
    });
  }

  setSVGFillColor(svgElement, color) {
    const elements = svgElement.querySelectorAll("*");
    elements.forEach((el) => {
      const fill = el.getAttribute("fill");
      const stroke = el.getAttribute("stroke");

      if (stroke && stroke !== "none" && !stroke.startsWith("url")) {
        el.setAttribute("stroke", color);
      }

      if (fill && fill !== "none" && !fill.startsWith("url")) {
        el.setAttribute("fill", color);
      }
    });
  }
}
