import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CurrentState = _resolveComponent("CurrentState");
  const _component_MainMenu = _resolveComponent("MainMenu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "gradient"
  }, null, -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_CurrentState)])]), _createVNode(_component_MainMenu)], 64);
}