export class CreepingLine {
  constructor(containerSelector) {
    this.container = document.querySelector(containerSelector);
    if (!this.container) {
      throw new Error(`Контейнер ${containerSelector} не найден`);
    }
    this.textElement = this.container.querySelector(".text");
    if (!this.textElement) {
      throw new Error(`Элемент .text внутри ${containerSelector} не найден`);
    }
    this.speed = 1;
    this.textQueue = [];
    this.running = false;
    this.currentPosition = 0;
  }

  start() {
    if (!this.textQueue.length) {
      console.error(
        "Очередь текста пуста. Используйте changeText для добавления строк."
      );
      return;
    }

    this.textElement.innerHTML =
      this.textQueue.join(" ") + " " + this.textQueue.join(" ");
    this.textWidth = this.textElement.offsetWidth;
    this.containerWidth = this.container.offsetWidth;

    this.running = true;
    this.animate();
  }

  stop() {
    this.running = false;
  }

  changeText(newTexts) {
    this.textQueue = newTexts;
    if (this.running) {
      this.start();
    }
  }

  animate() {
    if (!this.running) return;
    this.currentPosition -= this.speed;
    if (Math.abs(this.currentPosition) >= this.textWidth / 2) {
      this.currentPosition = 0;
    }
    this.textElement.style.transform = `translateX(${this.currentPosition}px)`;
    requestAnimationFrame(() => this.animate());
  }
}
