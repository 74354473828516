import { useTgUserDataStore } from "@/stores/tgUserData";

export class Index {
  init() {
    this.tgUserData = useTgUserDataStore();
    this.initTg();
  }

  initTg() {
    const tg = window.Telegram?.WebApp;
    if (tg) {
      let userData;
      tg.ready();

      if (process.env.VUE_APP_PRODUCTION === "True") {
        userData = tg.initData;
      } else {
        userData = process.env.VUE_APP_TG_DATA;
      }
      if (userData) {
        this.tgUserData.initUser(userData);
      }

      document.getElementById("close-btn")?.addEventListener("click", () => {
        tg.close();
      });
    }
  }
}
