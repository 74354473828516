import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/images/icons/menu_home.svg';
import _imports_1 from '../assets/images/icons/menu_shop.svg';
import _imports_2 from '../assets/images/icons/menu_boosts.svg';
import _imports_3 from '../assets/images/icons/menu_sub.svg';
import _imports_4 from '../assets/images/icons/menu_friends.svg';
const _hoisted_1 = {
  class: "menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    to: "/",
    class: "menu_item",
    "active-class": "active"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1), _createElementVNode("div", {
      class: "menu_item-text"
    }, "Главная", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    to: "/shop",
    class: "menu_item",
    "active-class": "active"
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
      src: _imports_1,
      alt: ""
    }, null, -1), _createElementVNode("div", {
      class: "menu_item-text"
    }, "Магазин", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    to: "/boosts",
    class: "menu_item",
    "active-class": "active"
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
      src: _imports_2,
      alt: ""
    }, null, -1), _createElementVNode("div", {
      class: "menu_item-text"
    }, "Улучшения", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    to: "/subscription",
    class: "menu_item",
    "active-class": "active"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
      src: _imports_3,
      alt: ""
    }, null, -1), _createElementVNode("div", {
      class: "menu_item-text"
    }, "Подписка", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    to: "/friends",
    class: "menu_item",
    "active-class": "active"
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
      src: _imports_4,
      alt: ""
    }, null, -1), _createElementVNode("div", {
      class: "menu_item-text"
    }, "Друзья", -1)])),
    _: 1
  })]);
}