import { defineStore } from "pinia";

interface User {
  auth_date: number;
  hash: string;
  query_id: string;
  signature: string;
  user: {
    id: number;
    allows_write_to_pm: boolean;
    first_name: string;
    language_code: string;
    last_name: string;
    photo_url: string;
    username?: string;
    is_premium?: boolean;
    is_bot?: boolean;
  };
}

export const useTgUserDataStore = defineStore("tgUserData", {
  state: () => ({
    data: {} as User,
    isAuth: false,
  }),
  actions: {
    initUser(value: string) {
      const params = new URLSearchParams(value);
      const userInfo = {} as User;

      params.forEach((paramValue, key) => {
        if (key === "user") {
          userInfo.user = JSON.parse(decodeURIComponent(paramValue));
        } else {
          (userInfo as any)[key] = paramValue;
        }
      });

      userInfo.auth_date = parseInt(
        userInfo.auth_date as unknown as string,
        10
      );
      this.data = userInfo;
      this.isAuth = true;
    },
  },
});
