import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, unref as _unref, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue";
import { Index } from "@/assets/js/index.js";
import NotMobile from "@/components/NotMobile.vue";
import { onMounted, ref } from "vue";
import { useTgUserDataStore } from "./stores/tgUserData";
import NotAuth from "./components/NotAuth.vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    const isDeviceMobile = ref(false);
    const tgUserData = useTgUserDataStore();
    onMounted(() => {
      const userAgentIsMobile = /Mobi|Android/i.test(navigator.userAgent);
      const screenIsNarrow = window.matchMedia("(max-width: 768px)").matches;
      isDeviceMobile.value = userAgentIsMobile && screenIsNarrow;
      const index = new Index();
      if (isDeviceMobile.value) {
        index.init();
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [!isDeviceMobile.value ? (_openBlock(), _createBlock(NotMobile, {
        key: 0
      })) : !_unref(tgUserData).isAuth ? (_openBlock(), _createBlock(NotAuth, {
        key: 1
      })) : (_openBlock(), _createBlock(_component_router_view, {
        key: 2
      }))]);
    };
  }
});