import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "non-mobile-container"
};
import packageJSON from "../../package.json";
export default /*@__PURE__*/_defineComponent({
  __name: 'NotAuth',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("h1", {
        class: "title"
      }, "XyRen", -1)), _cache[1] || (_cache[1] = _createElementVNode("p", {
        class: "description"
      }, " Вы не авторизованы, пожалуйста, авторизируйтесь в телеграм и попробуйте снова! ", -1)), _createElementVNode("span", null, "Версия " + _toDisplayString(_unref(packageJSON).version), 1)]);
    };
  }
});