import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "container"
};
import { ref } from "vue";
import CurrentState from "@/components/CurrentState.vue";
import MainMenu from "@/components/Menu.vue";
import ShopMain from "@/components/Shop/ShopMain.vue";
// import axios from "axios";
export default /*@__PURE__*/_defineComponent({
  __name: 'ShopPage',
  setup(__props) {
    const shopType = ref("shop");
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "gradient"
      }, null, -1)), _createElementVNode("div", _hoisted_2, [_createVNode(CurrentState), _createVNode(ShopMain, {
        shopType: shopType.value
      }, null, 8, ["shopType"])])]), _createVNode(MainMenu)], 64);
    };
  }
});