import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "shop_section"
};
const _hoisted_2 = {
  class: "shop_title"
};
const _hoisted_3 = {
  class: "shop_description"
};
const _hoisted_4 = {
  class: "shop_content-wrapper"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 2
};
import { onMounted, ref } from "vue";
import ShopItem from "../ShopItem.vue";
// import axios from "axios";
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentCoins',
  props: {
    shopType: {
      type: String,
      required: true
    }
  },
  emits: ["item-clicked"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const handleItemClicked = item => {
      emit("item-clicked", item);
    };
    const props = __props;
    const content = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const loadShopItems = async () => {
      loading.value = true;
      error.value = null;
      console.log(props.shopType);
      try {
        /*
          let apiUrl = "";
          if (loadType.value === "shop") {
            apiUrl = `https://api.example.com/shop/${section.value}`;
          } else if (loadType.value === "boosts") {
            apiUrl = `https://api.example.com/boosts/${section.value}`;
          }
          const response = await axios.get(apiUrl);
          content.value = response.data;
        */
        content.value = {
          title: "Покупка монет",
          description: "Вы можете напрямую приобрести монеты за деньги, чтобы тратить их на VPN-доступ или улучшения",
          items: [{
            id: 1,
            title: "Небольшой пакет",
            description: "+100K",
            price: 10
          }, {
            id: 2,
            title: "Базовый пакет",
            description: "+500K",
            price: 46
          }, {
            id: 3,
            title: "Продвинутый пакет",
            description: "+1M",
            price: 93,
            config: {
              header: "Оптимальный",
              color: "#93db6a",
              textColor: "#46513F"
            }
          }, {
            id: 4,
            title: "Большой пакет",
            description: "+5M",
            price: 465
          }, {
            id: 5,
            title: "Максимальный пакет",
            description: "+10M",
            price: 930
          }, {
            id: 6,
            title: "Экстра пакет",
            description: "+25M",
            price: 2320,
            config: {
              header: "Выгодно",
              color: "#4A3799",
              textColor: "#C4B6FF"
            }
          }],
          config: {
            rowCount: 2
          }
        };
      } catch (err) {
        error.value = "Не удалось загрузить данные.";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      loadShopItems();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [content.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(content.value.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(content.value.description), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value.items, item => {
        return _openBlock(), _createBlock(ShopItem, {
          "block-type": __props.shopType,
          key: item.id,
          item: item,
          config: content.value.config,
          onItemClicked: handleItemClicked
        }, null, 8, ["block-type", "item", "config"]);
      }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, "Загрузка...")), error.value ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(error.value), 1)) : _createCommentVNode("", true)], 64);
    };
  }
});