import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "non-mobile-container"
};
import { ref } from "vue";
import VueQrcode from "vue-qrcode";
import packageJSON from "../../package.json";
export default /*@__PURE__*/_defineComponent({
  __name: 'NotMobile',
  setup(__props) {
    const dataUrl = ref("");
    function onDataUrlChange(value) {
      dataUrl.value = value;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("h1", {
        class: "title"
      }, "XyRen", -1)), _cache[1] || (_cache[1] = _createElementVNode("p", {
        class: "description"
      }, " Отсканируйте QR-код ниже, чтобы играть на своём смартфоне! ", -1)), _createVNode(_unref(VueQrcode), {
        value: "https://t.me/xyren_game_bot/xyren_game",
        type: "image/png",
        color: {
          dark: '#000000',
          light: '#FFFFFF'
        },
        onChange: onDataUrlChange
      }), _createElementVNode("span", null, "Версия " + _toDisplayString(_unref(packageJSON).version), 1)]);
    };
  }
});