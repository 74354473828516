import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "modal"
};
const _hoisted_2 = {
  class: "modal_content"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "modal_content_title"
};
const _hoisted_5 = {
  class: "modal_content_description"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  class: "modal_content_price"
};
const _hoisted_9 = ["src"];
import { onMounted, onBeforeUnmount } from "vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'ModalWindow',
  props: {
    isVisible: {
      type: Boolean
    },
    type: {},
    item: {}
  },
  emits: ["close", "buy"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    /* eslint-disable no-unused-vars */
    const emit = __emit;
    /* eslint-enable no-unused-vars */
    const closeModal = () => {
      emit("close");
    };
    const buyItem = () => {
      emit("buy", props.item);
    };
    const handleEsc = event => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    onMounted(() => {
      window.addEventListener("keydown", handleEsc);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleEsc);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(() => [_ctx.isVisible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
          class: "modal-gradient",
          onClick: closeModal
        }), _createElementVNode("div", {
          class: "modal-container",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: _ctx.item.iconSrc,
          alt: "Icon",
          class: "modal_icon"
        }, null, 8, _hoisted_3), _createElementVNode("div", {
          class: "modal_content_close",
          onClick: closeModal
        }), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.title), 1), _createElementVNode("div", _hoisted_5, [_ctx.item.descriptionIcon ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.item.descriptionIcon,
          alt: "Description Icon"
        }, null, 8, _hoisted_6)) : _createCommentVNode("", true), _createElementVNode("span", {
          innerHTML: _ctx.item.description
        }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_ctx.item.priceIcon ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.item.priceIcon,
          alt: ""
        }, null, 8, _hoisted_9)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(_ctx.item.price) + " " + _toDisplayString(_ctx.type === "shop" ? "₽" : ""), 1)])]), _createElementVNode("div", {
          class: "modal_action-button",
          onClick: buyItem
        }, "Купить")])])) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
});