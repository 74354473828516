import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "shop_section"
};
const _hoisted_2 = {
  class: "shop_title"
};
const _hoisted_3 = {
  class: "shop_description"
};
const _hoisted_4 = {
  class: "shop_content-wrapper"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 2
};
import { onMounted, ref } from "vue";
import ShopItem from "../ShopItem.vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentVIP',
  props: {
    shopType: {
      type: String,
      required: true
    }
  },
  emits: ["item-clicked"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const handleItemClicked = item => {
      emit("item-clicked", item);
    };
    const props = __props;
    const content = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const loadShopItems = async () => {
      loading.value = true;
      error.value = null;
      console.log(props.shopType);
      try {
        content.value = {
          title: "VIP Статусы",
          description: "VIP-статусы предоставляют вам бонусы за каждый тап, ускорение автотаппера, а также дополнительные ежедневные бонусы. Действует в течении 30 дней",
          items: [{
            id: 1,
            title: "VIP Bronze",
            description: "+10% к монетам за тап\n+5% к автотапу\nЕжедневный бонус в 5,000 монет",
            price: 350,
            config: {
              titleIcon: "bronze",
              color: "#CD7F32"
            }
          }, {
            id: 2,
            title: "VIP Silver",
            description: "+25% к монетам за тап\n+15% к автотапу\nЕжедневный бонус в 15,000 монет",
            price: 500,
            config: {
              titleIcon: "silver",
              color: "#C0C0C0"
            }
          }, {
            id: 3,
            title: "VIP Gold",
            description: "+50% к монетам за тап\n+25% к автотапу\nЕжедневный бонус в 50,000 монет",
            price: 1250,
            config: {
              titleIcon: "gold",
              color: "#FFD700"
            }
          }, {
            id: 4,
            title: "Super VIP",
            description: "+100% к монетам за тап\n+50% к автотапу\nЕжедневный бонус в 100,000 монет",
            price: 1950,
            config: {
              titleIcon: "super",
              color: "#93DB6A"
            }
          }],
          config: {
            rowCount: 1
          }
        };
      } catch (err) {
        error.value = "Не удалось загрузить данные.";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      loadShopItems();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [content.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(content.value.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(content.value.description), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value.items, item => {
        return _openBlock(), _createBlock(ShopItem, {
          "block-type": __props.shopType,
          key: item.id,
          item: item,
          config: content.value.config,
          onItemClicked: handleItemClicked
        }, null, 8, ["block-type", "item", "config"]);
      }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, "Загрузка...")), error.value ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(error.value), 1)) : _createCommentVNode("", true)], 64);
    };
  }
});